'use client';

import { useEffect } from 'react';
import { useCookie, useEffectOnce, useInterval } from 'react-use';
import { Time } from '@sapphire/duration';
import * as Sentry from '@sentry/nextjs';
import { useSession } from 'next-auth/react';
import { toast } from 'sonner';
export const APP_CURRENCY = '_APP_CURRENCY_';
export const APP_CURRENCY_DEFAULT = 'HNL';
export default function SessionUpdate() {
  const {
    data: session,
    update
  } = useSession();
  const [_, setCurrencyValue] = useCookie(APP_CURRENCY);
  useInterval(async () => {
    try {
      if (update) {
        await update({
          force: true
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, Time.Hour);
  useEffect(() => {
    if (update) {
      const visibilityHandler = async () => {
        try {
          const sessionUpdated = update ? await update({
            force: true
          }) : null;
          if (!sessionUpdated) return;
          if (!!sessionUpdated?.user?.previousSelectedProject && sessionUpdated?.user?.selectedProject?.id !== sessionUpdated?.user?.previousSelectedProject?.id) {
            if (update) {
              await update({
                previousSelectedProject: sessionUpdated?.user?.selectedProject
              });
            }
            localStorage.setItem('reloadSignal', Date.now()?.toString());
          }
        } catch (error) {
          console.error(error);
        }
      };
      const handleStorage = (event: StorageEvent) => {
        if (event.key === 'reloadSignal') {
          toast.warning('Se ha cambiado el proyecto seleccionado, se recargarán tus pestañas.');
          window.location.reload();
        }
      };
      window.addEventListener('visibilitychange', visibilityHandler);
      window.addEventListener('storage', handleStorage);
      return () => {
        window.removeEventListener('visibilitychange', visibilityHandler);
        window.removeEventListener('storage', handleStorage);
      };
    }
  }, [update, session]);
  useEffect(() => {
    if (session?.user.selectedProject) {
      setCurrencyValue(session?.user.selectedProject.currencySymbol);
    }
    if (session?.user) {
      Sentry.setUser({
        email: session.user.email!,
        id: session.user.id,
        username: session.user.name!
      });
    } else {
      Sentry.setUser(null);
    }
  }, [session, setCurrencyValue]);
  useEffectOnce(() => {
    (async () => {
      if (update) {
        await update({
          force: true
        });
      }
    })();
  });
  return null;
}