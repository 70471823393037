'use client';

import { create } from 'zustand';
import { UserAuthenticateAssignProject } from '@/lib/types/api/user/user';
type ProjectSelectorStore = {
  isLoadingProject: boolean;
  loadingProjectId: UserAuthenticateAssignProject['id'] | null;
  loadingProjectName: string;
  setLoading: (loading: boolean, projectName: string, projectId: UserAuthenticateAssignProject['id'] | null) => void;
};
export const useProjectSelectorStore = create<ProjectSelectorStore>()((set, get) => ({
  isLoadingProject: false,
  loadingProjectId: null,
  loadingProjectName: '',
  setLoading: (loading, projectName, projectId) => {
    if (loading) {
      set({
        isLoadingProject: loading,
        loadingProjectId: projectId,
        loadingProjectName: projectName
      });
    } else {
      set({
        isLoadingProject: loading,
        loadingProjectId: null,
        loadingProjectName: ''
      });
    }
  }
}));