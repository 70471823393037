'use client';

import { Loader2Icon } from 'lucide-react';
import { useLoaderStore } from '@/src/lib/stores/generic-loader/loader-store';
import CompanyLogo from '@/components/shared/client/logos/company-logo';
export default function LoadingPage() {
  const isLoading = useLoaderStore(state => state.isLoading);
  const pageName = useLoaderStore(state => state.loadingPageName);
  if (!isLoading) return null;
  return <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 lg:text-2xl" data-sentry-component="LoadingPage" data-sentry-source-file="loader.tsx">
      <div className="flex w-full flex-col items-center gap-4 rounded-lg p-8">
        <CompanyLogo className={'w-44'} forceClassNames={true} forceWhite={true} data-sentry-element="CompanyLogo" data-sentry-source-file="loader.tsx" />
        <Loader2Icon className="animate-spin text-primary" size={120} data-sentry-element="Loader2Icon" data-sentry-source-file="loader.tsx" />
        <div className="mt-4 flex w-full flex-col items-center gap-y-3 text-center text-white">
          <p className="w-full max-w-[90%] text-center font-bold md:max-w-[70%] lg:max-w-[50%]">{pageName}</p>
        </div>
      </div>
    </div>;
}