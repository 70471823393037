'use client';

import Script from 'next/script';
import * as gtag from '@/lib/utils/functions/gtag-helper';
const GoogleAnalytics = () => {
  //You can show in the console the GA_TRACKING_ID to confirm

  return <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} strategy="afterInteractive" data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx" />
      <Script dangerouslySetInnerHTML={{
      __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${gtag.GA_TRACKING_ID}', {
                      page_path: window.location.pathname,
                      });

                      
                gtag('consent', 'default', {
                  'analytics_storage': 'granted'
              });
              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
            });
                    `
    }} id="gtag-init" strategy="afterInteractive" data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx" />
    </>;
};
export default GoogleAnalytics;