import { AlertTriangle, Dot } from 'lucide-react';
import { ExternalToast, toast } from 'sonner';
import { ApiResponse } from '@/lib/types/api/common';
export function ShowToastErrors({
  options,
  response
}: {
  options?: ExternalToast;
  response: ApiResponse<any>;
}) {
  const hasErrors = response.errors && response.errors?.length > 0;
  if (hasErrors) {
    return toast.warning(<div className="scrollbar-thumb-rounded-full scrollbar-track-rounded-full h-auto max-h-96	overflow-y-auto scrollbar-thin">
        <div className="flex flex-row items-center gap-2">
          <AlertTriangle color="#dc7609" />
          <h1 className="text-xs font-bold">{response.message}</h1>
        </div>
        {response.errors?.map((e, i) => <p className="flex items-center gap-1 font-medium" key={i}>
            <Dot className="shrink-0" />
            {e}
          </p>)}
      </div>, {
      ...options,
      closeButton: true,
      duration: 300000
    });
  }
  return toast.warning(response.message);
}