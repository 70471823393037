'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { cn } from '@nextui-org/react';
import { useTheme } from 'next-themes';
import WMBlue from '@/public/wm-blue.png';
import WMWhite from '@/public/wm-white.png';
interface CompanyLogoProps {
  className?: string;
  forceBlue?: boolean;
  forceClassNames?: boolean;
  forceWhite?: boolean;
}
export default function CompanyLogo({
  className,
  forceBlue,
  forceClassNames,
  forceWhite
}: CompanyLogoProps) {
  const [componentTheme, setComponentTheme] = useState<string | undefined>(undefined);
  const {
    theme
  } = useTheme();
  useEffect(() => {
    setComponentTheme(theme);
  }, [theme]);
  const companyLogo = (componentTheme === 'dark' || forceWhite) && !forceBlue ? WMWhite : WMBlue;
  if (forceBlue && forceWhite) {
    throw new Error('Cannot force both blue and white logos');
  }
  return <Image alt={'William & Molina Logo'} className={cn(forceClassNames ? '' : 'w-36', {
    [`${className}`]: className
  })} height={512} src={companyLogo.src} width={512} data-sentry-element="Image" data-sentry-component="CompanyLogo" data-sentry-source-file="company-logo.tsx" />;
}